@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/dist/fonts/GTEestiProDisplay-UltraBold.woff2') format('woff2'),
      url('/dist/fonts/GTEestiProDisplay-UltraBold.woff') format('woff'),
      url('/dist/fonts/GTEestiProDisplay-UltraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/dist/fonts/GTEestiProDisplay-Bold.woff2') format('woff2'),
      url('/dist/fonts/GTEestiProDisplay-Bold.woff') format('woff'),
      url('/dist/fonts/GTEestiProDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/dist/fonts/GTEestiProDisplay-Regular.woff2') format('woff2'),
      url('/dist/fonts/GTEestiProDisplay-Regular.woff') format('woff'),
      url('/dist/fonts/GTEestiProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Text';
  src: url('/dist/fonts/GTEestiProText-Bold.woff2') format('woff2'),
      url('/dist/fonts/GTEestiProText-Bold.woff') format('woff'),
      url('/dist/fonts/GTEestiProText-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Text';
  src: url('/dist/fonts/GTEestiProText-Medium.woff2') format('woff2'),
      url('/dist/fonts/GTEestiProText-Medium') format('woff'),
      url('/dist/fonts/GTEestiProText-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti Pro Text';
  src: url('/dist/fonts/GTEestiProText-Light.woff2') format('woff2'),
      url('/dist/fonts/GTEestiProText-Light.woff') format('woff'),
      url('/dist/fonts/GTEestiProText-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}