@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import '~react-modal-video/scss/modal-video.scss';

@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
//@import './layout/hamburger';
//@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/multiColumnPanel';

//wave
@import './modules/wave/wave';

// components
@import '../components';

@import './site/index';

//aos
@import "~aos/src/sass/aos";

//Theme
@import './theme/theme';

@media print {
  @import './print';
}

//banner-text-bar
section.banner-text-bar{
  .htmlPanel{
    p{
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

body.cmsedit.partial-page{
  #top{
    display: none;
  }
}

div.htmlPanel{
  h1, h2, h3, h4{
    span strong {
      color: inherit !important;
    }
  }
}

p{
  line-height: 1.4;
}

.blogTile__title{
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.1;
  @include media-breakpoint-up(sm){
    font-size: 18px;
    //line-height: 1.1;
  }

}

.form-control-sm{
  @include media-breakpoint-down(xs){
    font-size: 1rem;
  }
}

.htmlPanel hr{
  width: 50%;
  margin-left: unset;
  margin-right: unset;
}

div.cms_item.desktop-ig-feed{
  display: none;
  @include media-breakpoint-up(sm){
    display: block;
  }
}

div.cms_item.mobile-ig-feed{
  display: block;
  @include media-breakpoint-up(sm){
    display: none;
  }
}

div.pageTile__title.new-style{
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
}

body.cmsedit div#PopupSignupForm_0{
  display: none;
}

.gm-style iframe + div { border:none!important; }

// div.pointer-events{
//   border-color: transparent !important;
// }