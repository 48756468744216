.main-content {
  padding-top: map-get($top-bar-height, mobile);

  @include media-breakpoint-up(lg) {
    padding-top: map-get($top-bar-height, desktop);
  }
}

.actions {
  margin: 1rem 0;
  text-align: center;
  border-radius: 0;
  button {
    
    color: white;
    background-color: transparent;
    padding: '0.2rem 1rem';
	  border-color: white;
    border: '2px solid';
    border-radius: 15px;
    font-weight: 600;
    margin-right: 0.7rem;
    line-height: 1.4;
    &:hover{
      background-color: $link-hover-color;
      border-color: $link-hover-color;
    }
  }
}