// overriding bootstrap variables in _boostrap-override.scss

// $family-base: "GT Eesti Pro Text",sans-serif;
// $family-header: "GT Eesti Pro Display",serif;

$family-base: "GT Eesti Pro Text",sans-serif;
$family-header: "GT Eesti Pro Text",serif;

// font-size
$font-size-desktop: 21px;
$font-size-mobile: 16px;

$weight-light: 300;
$weight-normal: 300;
$weight-bold: 600;
$weight-ultra-bold: 800;

// colors - sister colours from bootrap
$color-blue:    #007bff;
$color-red:     #c20f2f;

// more colors
$color-gold:  #faa222;
$color-dark-gray: #3b3b3b;
$color-dark-blue: #132734;
$color-mid-blue: #5c6594;

$color-dk-orange:  #b35128;

//theme colors
$color-sand-stone : #fdf8e6;

$color-blie : #4d868f;
$color-green:   #85af96;
$color-orange:  #f26c33;
$color-yellow:  #ffef9a;

// site colors
$primary-color: $color-orange;
$font-color: $color-dark-gray;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color:$color-sand-stone;
$link-color: $primary-color;
$link-hover-color: $color-orange;

// top-bar
//$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 1.6rem, desktop: 2rem);
$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 2.7rem, small-device:2.8rem, tablet:3.5rem, desktop: 3.1rem);
$top-bar-height: (mobile: 44px, small-device:56px, tablet:56px, desktop: 56px);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;