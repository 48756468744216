$themes: (
    orange : ($color-orange, $color-orange),
    green : ($color-green, $color-green),
    blie : ($color-blie, $color-blie),
    yellow : ($color-yellow, $color-yellow),
);

@each $theme-code, $params in $themes{
    $color: nth($params, 1);
    div.body-content.theme-color-#{$theme-code}{       
        .cms_item.htmlPanel{
            h1, h2, h3, h4{
                b, strong{
                    color: $color;
                }
            }
            h1, h2, h3, h4{
                span strong {
                  color: inherit;
                }
            }
            hr{
                border-color: $color;
            }
        }
        h1.blogView__title{
            color: $color;
        }
        header.header-top-bar, .bottomMenu{
            background-color: $color;
        }
        div.wave-animation-bottom.in-header{
            color: $color !important;
        }
        .navBar_nav a {
            background-color: $color;
            &:hover, &:focus {
                background-color: darken($color, 10%);
            }
        }
        .atdw__map__filter .map-button{
            background-color: $color;
            border-color: $color;
            &.selected{
                background-color: darken($color, 30%);
                border-color: darken($color, 30%);
            }
        }
    }
}